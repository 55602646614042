import _ from "lodash";
import { getBatchesByNames, getBatchesByTimeRange as getBatchesByTimeRangeModel, IBatchConfig, IBatchDefinition, IBatchDefinitions } from "./model";

export const getBatchById = (state: IStoreState, id: string) => (
    state.batches?.[id]
);

export const getBatchesByTimeRange = (state: IStoreState, begin: number, end: number) => (
    getBatchesByTimeRangeModel(state.batches, begin, end)
);

export const getCurrentBatch = (state: IStoreState, currentDate: Date) => (
    // start date is after current date and duration is not defined
    _.find(state.batches, ({ startDate, startTime, duration }) => (startDate + startTime) <= currentDate.valueOf() && duration == 0)
);

export const getBatchConfig = ({ batchConfig }: IStoreState): IBatchConfig => batchConfig;

/**
* Get all batches with names that are wihtin a list.
*/
export const getBatchesWithSpecificNamesAndByTimeRange = (state: IStoreState, names: string[], begin: number, end: number) => (
    getBatchesByNames(getBatchesByTimeRange(state, begin, end), names)
);

export const getBatchDefinitions = ({ batchDefinitions }: IStoreState): IBatchDefinitions => batchDefinitions;

export const getBatchDefinitionsById = (state: IStoreState, id: string): IBatchDefinition => state.batchDefinitions?.[id];